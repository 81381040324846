import React from 'react'
import Layout from '../components/layout'
import PageHeading from '../UI/PageHeading/PageHeading'
import PageTransition from 'gatsby-plugin-page-transitions'
import { Text, Title } from './../UI/Typing'
import Animotion from './../UI/Animotion'
import { Parallax } from 'react-scroll-parallax'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { RoomButton } from './../UI/Button/Button'
import './wines.styl'

import HeadImg from './../Assets/images_pg/cook/headvini.jpg'

import Brush from './../Assets/brush.svg'
import Vino1 from './../Assets/pikuntera.png'
import Vino2 from './../Assets/riesling.png'
import Vino3 from './../Assets/rosso.png'
import Vino4 from './../Assets/brut.png'
import Vino5 from './../Assets/ribolla.png'
import Vino6 from './../Assets/pinot.png'

import PdfBrut from './../Assets/pdf/brut.pdf'
import PdfRosso from './../Assets/pdf/rosso.pdf'
import PdfRibolla from './../Assets/pdf/ribolla.pdf'
import PdfRiesl from './../Assets/pdf/riesl.pdf'
// import PdfRibolla from './../Assets/pdf/ribolla.pdf'
import PdfPinot from './../Assets/pdf/pinot.pdf'

const WineRow = props => (
  <div className={`WineRow ${props.position}`}>
    <img className='brush' src={Brush} alt='' />
    <div>
      <Parallax offsetYMax={20} offsetYMin={-20}>
        <figure>
          <img src={props.image} alt='' />
        </figure>
      </Parallax>
      <div className='wine-info'>
        <Title>{props.title}</Title>
        <Text>
          <div>
            {props.text}

            {props.pdf ? (
              <div>
                <a target='_blank' href={props.pdf} className='pdf-link'>
                  <FormattedMessage id='tech_sheet_download' />
                </a>
              </div>
            ) : (
                ''
              )}
          </div>
        </Text>
      </div>
    </div>
  </div>
)

const WinePage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        image={HeadImg}
        title={<FormattedMessage id='wine' />}
        subtitle={<FormattedMessage id='winesSubtitle' />}
        text={<FormattedMessage id='winesDescription' />}
      />
      <section>
        <Animotion>
          <WineRow
            image={Vino2}
            title={<FormattedMessage id='wines5Title' />}
            text={<FormattedMessage id='wines5Description' />}
            position='left'
          />
        </Animotion>
        <Animotion>
          <WineRow
            image={Vino2}
            title={<FormattedMessage id='wines6Title' />}
            text={<FormattedMessage id='wines6Description' />}
            position='right'
            pdf={PdfRiesl}
          />
        </Animotion>
        <Animotion>
          <WineRow
            image={Vino3}
            title={<FormattedMessage id='wines3Title' />}
            text={<FormattedMessage id='wines3Description' />}
            position='left'
            pdf={PdfRosso}
          />
        </Animotion>
        <Animotion>
          <WineRow
            image={Vino5}
            title={<FormattedMessage id='wines7Title' />}
            text={<FormattedMessage id='wines7Description' />}
            position='right'
            pdf={PdfRibolla}
          />
        </Animotion>
        <Animotion>
          <WineRow
            image={Vino6}
            title={<FormattedMessage id='wines8Title' />}
            text={<FormattedMessage id='wines8Description' />}
            position='left'
            pdf={PdfPinot}
          />
        </Animotion>
        <Animotion>
          <WineRow
            image={Vino4}
            title={<FormattedMessage id='wines4Title' />}
            text={<FormattedMessage id='wines4Description' />}
            position='right'
            pdf={PdfBrut}
          />
        </Animotion>
      </section>
    </PageTransition>
  </Layout>
)

export default WinePage
